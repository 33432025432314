.header {
      position: fixed;
      top: 0;
      width: 100%;
      transition: height 0.3s, padding 0.3s;
      background-color: #fff;
      /* Default light background */
      z-index: 1000;
}

.headerUpper {
      text-transform: uppercase;
      padding: 10px 0;
      color: rgba(255, 255, 255, 0.7);
      background-color: rgba(0, 0, 0, 0.9);
}

.headerLower {
      font-size: 120%;
      padding: 10px 0 15px 0;
      color: var(--dark);
      background-color: rgba(0, 0, 0, 0.05);
}

.light {
      background-color: rgba(255, 255, 255, 1);
}

.dark {
      background-color: rgba(0, 0, 0, 1);
}

.navMenu svg {
      float: right;
}