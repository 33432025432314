#Footer {
      padding: 75px 0;
      color: rgba(255, 255, 255, 0.6);
      background-color: var(--dark);
}

#Footer a {
      color: inherit;
      display: block;
      margin-bottom: 10px;
}

#Footer a:hover {
      color: rgba(255, 255, 255, 0.8);
}