.TestimonialCard {
      font-weight: 300;
      padding: 100px;
      background-color: var(--medium);
      color: var(--dark);
}

.ReviewStars {
      font-size: 54px;
      color: rgba(0, 0, 0, 1);
}

.ReviewStars svg {
      margin-right: 10px;
}