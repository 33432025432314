/* let breakPoints = {
  xs: '575.999', //<
  sm: '576px', // >=
  md: '768px', //  >=
  lg: '992px', // >=
  xl: '1200px', // >=
  xxl: '1600px', // >==
} */

:root {
      --white: rgba(255, 255, 255, 1);
      --black: rgba(0, 0, 0, 1);
      --dark: rgba(0, 0, 0, 0.8);
      --medium-dark: rgba(0, 0, 0, 0.4);
      --medium: rgba(0, 0, 0, 0.1);
      --light: rgba(0, 0, 0, 0.02);

      --green: rgba(49, 163, 81, 1);
      --red: rgba(227, 64, 52, 1);
      --blue: rgba(63, 130, 238, 1);
      --yellow: rgba(241, 183, 6, 1);
}

body, html {
      font-family: 'Roboto', sans-serif;
      color: var(--black);
      margin: 0;
      padding: 0;
}

*, *::before, *::after {
      box-sizing: border-box;
}


/* div {
      border: 1px dashed red;
} */

/* p, ul, li, a, label {
      font-size: 16px;
} */

p {
      color: var(--dark);
      line-height: 1.6;
}

h1 {
      font-family: 'Urbanist', sans-serif;
      font-weight: 500;
      display: block;
      color: var(--dark);
      /* font-size: 84px; */
      padding-bottom: 10px;
}

h2, h3 {
      font-family: 'Urbanist', sans-serif;
      font-weight: 600;
}

h2 {
      color: var(--dark);
      /* font-size: 54px;
      line-height: 1.1; */
      letter-spacing: 1px;
      font-weight: 500;
}

h3 {
      color: var(--dark);
      /* font-size: 32px; */
      letter-spacing: 0px;
      font-weight: 500;
}

.clickable {
      cursor: pointer;
}

.card {
      height: 140px;
      text-align: center;
      padding: 120px 50px 25px 50px;
      font-size: 34px;
      font-weight: 700;
      color: var(--white);
      background-color: var(--medium);
      margin-bottom: 25px;
}

.superscript {
      font-family: 'Urbanist', sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      opacity: 0.5;
}

.ant-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
}

.ant-modal-title {
      font-size: 26px !important;
}

.ant-modal-footer {
      margin-top: 25px !important;
}

input {
      transition: width 0.3s ease-in-out;
}


.ant-btn-primary {
      background-color: var(--dark);
      color: var(--white);
}

.ant-btn-lg {
      font-size: 22px;
      padding: 10px 30px;
      height: auto;
}

.ant-notification-notice-wrapper {
      border-radius: 2px !important;
}

.ant-notification-notice-message {
      margin-bottom: 0px !important;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
      width: 500px;
}

.ant-notification-notice-success {
      border-left: 10px solid var(--green);
}

.ant-notification-notice-info {
      border-left: 10px solid var(--blue);
}

.ant-notification-notice-warning {
      border-left: 10px solid var(--yellow);
}

.ant-notification-notice-error {
      border-left: 10px solid var(--red);
}