#LogoCarousel {

      padding: 75px 0;

}

.LogoCarouselCell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 50px 0;
}

.LogoCarouselCell img {
      filter: grayscale(100%);
}