#BannerCta {
      padding: 70px 0 80px 0;
      background-color: var(--medium);
}

#BannerCta h2 {
      padding: 0;
      margin: 0;
}

#BannerCta p {
      padding: 0;
      margin: 0;
}